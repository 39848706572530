<template>
    <div>
        <div class="shadow w-1/2 mx-auto mt-5 rounded-md">
            <p v-if="!isOk"
                class="flex items-center justify-center bg-red-600 px-4 py-3 text-sm font-medium text-white">
                {{ $i18n('settings.not-saved') }}
            </p>
            <div class="px-4 pt-5">
                <h3 class="text-lg font-medium leading-6 text-gray-900">{{ $i18n('user.settings') }}</h3>
            </div>
            <div class="space-y-6 bg-white px-4 py-5">
                <fieldset v-for="(groupSettings, groupName) in settings" :key="groupName">
                    <legend class="sr-only">{{ groupName }}</legend>
                    <div class="text-base font-medium text-gray-900">{{ $i18n(`${groupName}.title`)}}</div>
                    <div v-for="(setting, settingName) in groupSettings" :key="settingName" class="mt-7 space-y-4">
                        <label class="inline-flex items-start">
                            <div class="flex items-center">
                                <template>
                                    <input v-if="hasCheckbox(settingName)" v-model="settings[groupName][settingName]"
                                        type="checkbox"
                                        class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500">
                                    <input v-else v-model.number="settings[groupName][settingName]" type="number"
                                        min="1" autocomplete="given-name"
                                        class="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500">
                                </template>
                            </div>
                            <div class="ml-3 text-sm self-center">
                                <span class="font-medium text-gray-700">{{ $i18n(`export.${settingName}`) }}</span>
                            </div>
                        </label>
                    </div>
                </fieldset>
            </div>
            <div class="bg-gray-50 px-4 py-3 text-right">
                <button @click="patchSettings" type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    {{ $i18n('action.save') }} <template v-if="!isReady"> <svg
                            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg> </template>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { useSettings } from '@/composables/useSettings'
import { usePatchSettings } from '@/composables/usePatchSettings'

export default {
    setup: () => {
        const settings = useSettings()
        const { isReady, isOk, patchSettings } = usePatchSettings(settings)
        const hasCheckbox = property => {
            if (
                property === 'images_fit_height' ||
                property === 'images_fit_width'
            ) {
                return false
            }
            return true
        }
        return {
            patchSettings,
            settings,
            hasCheckbox,
            isReady,
            isOk
        }
    }
}
</script>
