import { axiosInstance } from '@/config/axios';
import { shallowRef, toRaw } from 'vue';
import delay from '@/utils/delay';
import store from '@/store';

export const usePatchSettings = settings => {
    const isReady = shallowRef(true)
    const isOk = shallowRef(true)
    const patchSettings = delay((...args) => {
        const signal = args?.[2]
        isReady.value = false
        axiosInstance()
            .then(axios => {
                return axios.patch('users/@me/settings', toRaw(settings), {
                    signal
                })
            })
            .then(() => {
                store.dispatch('users/fetchCurrentUserProfile')
                isOk.value = true
            })
            .catch(() => {
                isOk.value = false
            })
            .finally(() => {
                isReady.value = true
            })
    })

    return { isReady, isOk, patchSettings }
}
