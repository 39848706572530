import { reactive, shallowRef, computed, watchEffect } from 'vue';
import store from '@/store';

export const useSettings = () => {
    const imagesFitHeight = shallowRef(200)
    const imagesFitWidth = shallowRef(350)
    const settings = computed(() => store?.getters?.['users/currentUserProfile']?.settings)
    const withStrictIntType = value => {
        return Math.abs(Math.trunc(value)) || 1
    }
    const data = reactive({
        feed: {
            'highlight_keywords_enabled': false
        },
        export: {
            'highlight_keywords_enabled': false,
            'highlight_search_enabled': false,
            'resize_images_enabled': false,
            'images_fit_height': computed({
                get: () => withStrictIntType(imagesFitHeight.value),
                set: value => {
                    imagesFitHeight.value = withStrictIntType(value)
                }
            }),
            'images_fit_width': computed({
                get: () => withStrictIntType(imagesFitWidth.value),
                set: value => {
                    imagesFitWidth.value = withStrictIntType(value)
                }
            })
        }
    })
    watchEffect(() => {
        if ((settings?.value ?? false)) {
            data.feed['highlight_keywords_enabled'] = settings?.value?.feed?.['highlight_keywords_enabled']
            data.export['highlight_keywords_enabled'] = settings?.value?.export?.['highlight_keywords_enabled']
            data.export['highlight_search_enabled'] = settings?.value?.export?.['highlight_search_enabled']
            data.export['resize_images_enabled'] = settings?.value?.export?.['resize_images_enabled']
            data.export['images_fit_height'] = settings?.value?.export?.['images_fit_height']
            data.export['images_fit_width'] = settings?.value?.export?.['images_fit_width']
        }
    })
    return data
}